/* Game modal */
.Modal {
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.ModalWrap {
  background-color: #ffffffee;
  border-radius: 25px;
  display: flex;
  flex-direction: row;
  max-width: 640px;
  padding: 30px 20px;
  width: 90%;
  max-height: 80vh;
}

.Modal.Success .ModalWrap {
  border: solid 2px #bed630;
}

.Modal.Error .ModalWrap {
  border: solid 2px #e01212;
}

.ModalContent {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.ModalContent.Full {
  padding: 0;
  width: 100%;
}

.ModalContent .MiddleButton {
  margin-left: 0;
  margin-right: 0;
}

.ModalButtonsContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
}

.ModalContent .AppButtonDark,
.ModalContent .AppButtonLight {
  margin-bottom: 5px;
  padding: 7px 30px 7px 15px;
}

.ModalTitle {
  color: #68ac45;
  font-size: 20px;
  line-height: 1.2;
  margin-bottom: 20px;
  text-align: center;
  width: 100%;
}

.Modal.Success .ModalTitle {
  color: #68ac45;
}

.Modal.Error .ModalTitle {
  color: #e01212;
}

.ModalMessage {
  font-size: 12px;
  line-height: 1.2;
  margin-bottom: 20px;
  text-align: center;
  color: #212029;
  max-height: calc(100% - 80px);
}

.GameModal.LongText .ModalMessage {
  max-height: calc(100% - 160px);
}

.ModalNextLevelButton {
  margin-bottom: 10px;
}

.ModalSquirrel {
  background: url('../assets/images/katerica-left.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: right;
  display: none;
}

.Modal .AppButtonDark,
.Modal .AppButtonLight {
  width: 100%;
}

@media (min-width: 992px) {
  .ModalSquirrel {
    display: block;
    width: 35%;
  }

  .ModalTitle {
    font-size: 24px;
    text-align: left;
  }

  .SquirrelGameModal .ModalContent {
    padding-left: 20px;
    width: 65%;
  }

  .ModalMessage {
    text-align: left;
  }

  .GameModal.LongText .ModalMessage {
    max-height: calc(100% - 100px);
  }

  .ModalContent .AppButtonDark,
  .ModalContent .AppButtonLight {
    margin-bottom: 10px;
    padding: 10px 30px 10px 15px;
  }

  .ModalNextLevelButton {
    font-size: 20px !important;
  }

  .Modal .AppButtonDark,
  .Modal .AppButtonLight {
    width: auto;
  }

  .ModalButtonsContainer {
    flex-direction: row;
  }
}
