@import 'fonts.css';
@import 'Modal.css';
@import 'IntroScreen.css';
@import 'GameScreen.css';
@import 'StartScreen.css';
@import 'GameFoodTrashes.css';
@import 'GameTrashManagement.css';
@import 'ordering-game/OrderingGame.css';
@import url(../../node_modules/react-custom-scroll/dist/customScroll.css);

:root {
  --app-height: 100%;
}

* {
  box-sizing: border-box;
}

html,
body {
  padding: 0;
  margin: 0;
}

.App {
  font-family: 'Montserrat Alternates Regular';
  height: 100vh;
  height: var(--app-height);
  margin: 0 auto;
  text-align: center;
  width: 100%;
}

.AppWrap {
  margin: 0 auto;
  position: relative;
  width: 100%;
  height: 100%;
}

.AppLink {
  color: #61dafb;
}

.AppButtonDark {
  background-color: #68ac45;
  border: solid 2px #bed630;
  border-radius: 7.5px;
  color: #fff;
  cursor: pointer;
  font-family: 'Montserrat Alternates Bold';
  font-size: 12px;
  font-weight: 700;
  line-height: 1.2;
  padding: 10px 15px;
  position: relative;
  text-align: left;
  text-decoration: none;
}

.AppButtonDark.left {
  padding: 10px 15px 10px 30px;
}

.AppButtonDark.left:before {
  content:'';
  background: url('../assets/images/play-icon.png');
  background-position: center;
  background-size: contain;
  padding: 7px;
  background-repeat: no-repeat;
  margin-right: 10px;
  transform: rotate(180deg);
  position: absolute;
  left: 10px;
}

.AppButtonDark.right {
  padding: 10px 30px 10px 15px;
}

.AppButtonDark.right:after {
  content: '';
  background: url('../assets/images/play-icon.png');
  background-position: center;
  background-size: contain;
  padding: 7px;
  background-repeat: no-repeat;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translate(0, -50%);
}

.AppButtonDark:hover {
  background-color: #bed630;
}

.AppButtonLight {
  background-color: #fff;
  border: solid 2px #bed630;
  border-radius: 7.5px;
  color: #68ac45;
  cursor: pointer;
  font-size: 12px;
  line-height: 1.2;
  padding: 10px 15px;
  position: relative;
  text-align: left;
  text-decoration: none;
}

.AppButtonLight:hover {
  background-color: #bed630;
  color: #fff;
}

.AppButtonDark.center,
.AppButtonLight.center {
  text-align: center;
}

.ScreenWrap {
  position: relative;
  width: 100%;
  height: 100vh;
  height: var(--app-height);
  /* height: -webkit-fill-available; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;
}

.AppImage {
  background-repeat: no-repeat !important;
  background-size: contain !important;
  width: 100%;
  height: 100%
}

.rcs-outer-container {
  position: relative;
}

.rcs-positioning {
  position: absolute !important;
  right: 0;
  top: 0;
  bottom: 0;
  width: 20px;
}

.rcs-custom-scroll .rcs-custom-scrollbar {
  background: #bed63055;
  bottom: 0;
  opacity: 1;
  position: absolute;
  right: 0;
  top: 0;
  width: 10px;
}

.rcs-inner-handle {
  background: #bed630 !important;
  border-radius: 0 !important;
  height: 100% !important;
  margin: 0 !important;
}

.rcs-inner-container {
  padding-right: 20px;
}
