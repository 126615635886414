.GameCanvasScreen-FoodTrashes {
  background: url('../assets/images/food-trashes-game/background-clear.png');
}

.GameCanvasScreen-FoodTrashes .RecycleBinsContainer {
  width: 100%;
}

.GameCanvasScreen-FoodTrashes .GameCanvasScreenExplain {
  margin-top: 0;
}

.GameCanvasScreen-FoodTrashes .TrashesContainer {
  top: 80px;
}

.App.horizontal .GameCanvasScreen-FoodTrashes .TrashesContainer {
  max-width: 60%;
  right: 40%;
  bottom: 0;
}

.App.horizontal .GameCanvasScreen-FoodTrashes .RecycleBinsContainerWrapper {
  max-width: 40%;
  bottom: 0;
  left: 60%;
  right: 0;
  top: 110px;
}

.App.vertical .GameCanvasScreen-FoodTrashes .FoodTrashesGameContainer {
  height: calc(100vh - 130px);
  position: relative;
}

.App.vertical .GameCanvasScreen-FoodTrashes .TrashesContainer {
  height: 60%;
  top: 0;
}

.App.vertical .GameCanvasScreen-FoodTrashes .RecycleBinsContainerWrapper {
  height: 35%;
}

.App.horizontal .GameCanvasScreen-FoodTrashes .RecycleBin {
  width: 50%;
  height: 70%;
}

.App.vertical .GameCanvasScreen-FoodTrashes .RecycleBin {
  width: 50%;
  height: 100%;
  min-height: auto;
}

.App.horizontal .GameCanvasScreen-FoodTrashes .RecycleBinfreezer {
  background: transparent;
  position: absolute;
  right: 0;
  height: 30% !important;
  bottom: 15%;
  z-index: 100;
}

.App.vertical .GameCanvasScreen-FoodTrashes .RecycleBinfreezer {
  background: transparent;
  position: absolute;
  right: 0;
  height: 40% !important;
  bottom: 0;
  z-index: 100;
}

.RecycleBinfridge .RecycleBinSuccess,
.RecycleBinfridge .RecycleBinError {
  top: 35%;
}

.GameCanvasScreen-FoodTrashes .QuestionPictures {
  background-size: contain !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
}

.GameCanvasScreen-FoodTrashes .Question1 .QuestionPictures {
  background: url('../assets/images/food-trashes-game/questions/question-1-picture.png');
}

.GameCanvasScreen-FoodTrashes .Question2 .QuestionPictures {
  background: url('../assets/images/food-trashes-game/questions/question-2-picture.png');
}

.GameCanvasScreen-FoodTrashes .Question3 .QuestionPictures {
  background: url('../assets/images/food-trashes-game/questions/question-3-picture.png');
}

.GameCanvasScreen-FoodTrashes .Question4 .QuestionPictures {
  background: url('../assets/images/food-trashes-game/questions/question-4-picture.png');
}

.QuestionModal .ModalWrap {
  max-width: 800px;
}

@media (min-width: 576px) {}

@media (min-width: 768px) {
  .GameCanvasScreen-FoodTrashes .QuestionPictures {
    width: 65%;
  }

  .GameCanvasScreen-FoodTrashes .QuestionPictures {
    width: 35%;
  }
}
