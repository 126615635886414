.ContentScreen.StartScreen .ContentScreenContent {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: flex-end;
}

.StartScreenChooseGame {
  font-size: 24px;
  margin-bottom: 10px;
  text-align: left;
  text-transform: uppercase;
}

.StartScreenGameLaunchersContainer
{
  display: flex;
  flex-direction: row;
  width: 100%;
  max-height: 380px;
  height: 100%;
}

.StartScreenGameLaunchersContainer > * {
  flex: 1;
}

.StartScreenGameLaunchersContainer a {
  text-decoration: none;
}

.StartScreenGameLauncher {
  background-repeat: no-repeat !important;
  background-size: cover !important;
  border: 10px solid #bed630;
  color: #212029;
  height: 100%;
  font-size: 18px;
  margin: 0 10px;
  max-height: 320px;
  padding: 10px;
  text-align: left;
  text-decoration: none;
  text-transform: uppercase;
}

.StartScreenGameLauncher:first-child {
  margin-left: 0;
}

.StartScreenGameLauncher.Recycling {
  background: url('../assets/images/start/game-1-mobile.png');
}

.StartScreenGameLauncher.TrashManagement {
  background: url('../assets/images/start/game-2-mobile.png');
}

.StartScreenGameLauncher.TrashFood {
  background: url('../assets/images/start/game-3-mobile.png');
}

.StartScreenKids {
  background: url('../assets/images/start/kids.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

@media (max-width: 976px) {
  .ContentScreen.StartScreen {
    background-attachment: fixed;
    height: auto;
  }

  .ContentScreen.StartScreen .ScreenWrap {
    display: block;
    height: auto;
  }

  .ContentScreen.StartScreen .ContentScreenContent {
    max-height: none;
    margin-top: 20px;
  }

  .StartScreenGameLaunchersContainer {
    flex-direction: column;
    max-height: none;
  }

  .StartScreenGameLauncher {
    height: 320px;
    margin-bottom: 20px;
  }
}
