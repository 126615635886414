/* #### Generated By: http://www.cufonfonts.com #### */

@font-face {
  font-family: 'Montserrat Alternates Regular';
  font-style: normal;
  font-weight: normal;
  src: local('Montserrat Alternates Regular'), url('../assets/fonts/MontserratAlternates-Regular.woff') format('woff');
}


@font-face {
  font-family: 'Montserrat Alternates Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Montserrat Alternates Italic'), url('../assets/fonts/MontserratAlternates-Italic.woff') format('woff');
}


@font-face {
  font-family: 'Montserrat Alternates Thin';
  font-style: normal;
  font-weight: normal;
  src: local('Montserrat Alternates Thin'), url('../assets/fonts/MontserratAlternates-Thin.woff') format('woff');
}


@font-face {
  font-family: 'Montserrat Alternates Thin Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Montserrat Alternates Thin Italic'), url('../assets/fonts/MontserratAlternates-ThinItalic.woff') format('woff');
}


@font-face {
  font-family: 'Montserrat Alternates ExtraLight';
  font-style: normal;
  font-weight: normal;
  src: local('Montserrat Alternates ExtraLight'), url('../assets/fonts/MontserratAlternates-ExtraLight.woff') format('woff');
}


@font-face {
  font-family: 'Montserrat Alternates ExtraLight Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Montserrat Alternates ExtraLight Italic'), url('../assets/fonts/MontserratAlternates-ExtraLightItalic.woff') format('woff');
}


@font-face {
  font-family: 'Montserrat Alternates Light';
  font-style: normal;
  font-weight: normal;
  src: local('Montserrat Alternates Light'), url('../assets/fonts/MontserratAlternates-Light.woff') format('woff');
}


@font-face {
  font-family: 'Montserrat Alternates Light Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Montserrat Alternates Light Italic'), url('../assets/fonts/MontserratAlternates-LightItalic.woff') format('woff');
}


@font-face {
  font-family: 'Montserrat Alternates Medium';
  font-style: normal;
  font-weight: normal;
  src: local('Montserrat Alternates Medium'), url('../assets/fonts/MontserratAlternates-Medium.woff') format('woff');
}


@font-face {
  font-family: 'Montserrat Alternates Medium Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Montserrat Alternates Medium Italic'), url('../assets/fonts/MontserratAlternates-MediumItalic.woff') format('woff');
}


@font-face {
  font-family: 'Montserrat Alternates SemiBold';
  font-style: normal;
  font-weight: normal;
  src: local('Montserrat Alternates SemiBold'), url('../assets/fonts/MontserratAlternates-SemiBold.woff') format('woff');
}


@font-face {
  font-family: 'Montserrat Alternates SemiBold Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Montserrat Alternates SemiBold Italic'), url('../assets/fonts/MontserratAlternates-SemiBoldItalic.woff') format('woff');
}


@font-face {
  font-family: 'Montserrat Alternates Bold';
  font-style: normal;
  font-weight: normal;
  src: local('Montserrat Alternates Bold'), url('../assets/fonts/MontserratAlternates-Bold.woff') format('woff');
}


@font-face {
  font-family: 'Montserrat Alternates Bold Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Montserrat Alternates Bold Italic'), url('../assets/fonts/MontserratAlternates-BoldItalic.woff') format('woff');
}


@font-face {
  font-family: 'Montserrat Alternates ExtraBold';
  font-style: normal;
  font-weight: normal;
  src: local('Montserrat Alternates ExtraBold'), url('../assets/fonts/MontserratAlternates-ExtraBold.woff') format('woff');
}


@font-face {
  font-family: 'Montserrat Alternates ExtraBold Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Montserrat Alternates ExtraBold Italic'), url('../assets/fonts/MontserratAlternates-ExtraBoldItalic.woff') format('woff');
}


@font-face {
  font-family: 'Montserrat Alternates Black';
  font-style: normal;
  font-weight: normal;
  src: local('Montserrat Alternates Black'), url('../assets/fonts/MontserratAlternates-Black.woff') format('woff');
}


@font-face {
  font-family: 'Montserrat Alternates Black Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Montserrat Alternates Black Italic'), url('../assets/fonts/MontserratAlternates-BlackItalic.woff') format('woff');
}
