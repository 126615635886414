.GameCanvasScreenTrashesManagement .GameCanvasScreenHeader {
  position: absolute;
  left: 20px;
  right: 20px;
  z-index: 1;
}

.GameCanvasScreen-TrashesManagement {
  background: url('../assets/images/trash-management-game/background.png');
  height: 100%;
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  filter: blur(4px);
  opacity: 0.7;
}

.TrashesCityContainer {
  background: url('../assets/images/trash-management-game/background.png');
  background-size: contain;
  margin-top: -20px;
}

.TrashesCityContainerPart {
  border: 2px dashed #e01212;
  float: left;
  height: 50%;
  width: 25%;
  position: relative;
}

.TrashesCityContainerPart.Answered {
  border: 0;
}

.TrashesCityContainerPart1,
.TrashesCityContainerPart2,
.TrashesCityContainerPart3 {
  border-right: 0;
  border-bottom: 0;
}

.TrashesCityContainerPart5,
.TrashesCityContainerPart6,
.TrashesCityContainerPart7 {
  border-right: 0;
}

.TrashesCityContainerPart4 {
  border-bottom: 0;
}

.TrashesCityContainerPart img {
  height: 100%;
  width: 100%;
}

.TrashesCityContainerPartOverlay {
  background-color: rgba(190, 214, 48, 0.5);
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}

.QuestionModal .QuestionTitle {
  color: #68ac45;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: left;
}

.QuestionModal .QuestionAnswers {
  width: 100%;
}

.QuestionModal .QuestionAnswer {
  background-color: #fff;
  border: solid 2px #bed630;
  border-radius: 8px;
  color: #68ac45;
  font-size: 14px;
  margin-bottom: 8px;
  padding: 8px;
  text-align: left;
  width: 100%;
}

.QuestionModal .QuestionAnswer:hover {
  background-color: #f0f7b0;
  cursor: pointer;
}

.QuestionModal .QuestionAnswer.Correct {
  background-color: #68ac45;
  color: #ffffff;
}

.QuestionModal .QuestionAnswer.Wrong {
  background-color: #e01212;
  color: #ffffff;
}


@media (min-width: 576px) {

}

@media (min-width: 768px) {
  .QuestionModal .QuestionTitle {
    font-size: 20px;
  }

  .QuestionModal .QuestionAnswer {
    font-size: 15px;
    margin-bottom: 10px;
  }
}

@media (min-width: 992px) {
  .QuestionModal .QuestionTitle {
    font-size: 24px;
  }

  .QuestionModal .QuestionAnswer {
    font-size: 16px;
    margin-bottom: 12px;
  }
}
