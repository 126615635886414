.OrderingGame {
  height: calc(100% - 110px);
  width: 100%;
}

.OrderingGameStepsPreview {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  height: 70%;
  width: 100%;
}

.OrderingStepPreview {
  align-items: center;
  display: flex;
  flex: 1 0 33%;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 20px;
  text-align: center;
}

.OrderingStepPreviewImage {
  background-size: contain !important;
  background-repeat: no-repeat !important;
  margin-top: 15px;
  width: 90%;
  padding-top: 100%;
  background-position: center;
}

.OrderingStepPreviewImage1 {
  background: url('../../assets/images/food-trashes-game/ordering/opakovane.png');
}

.OrderingStepPreviewImage2 {
  background: url('../../assets/images/food-trashes-game/ordering/dostavka.png');
}

.OrderingStepPreviewImage3 {
  background: url('../../assets/images/food-trashes-game/ordering/hladilnik.png');
}

.OrderingStepPreviewImage4 {
  background: url('../../assets/images/food-trashes-game/ordering/brane.png');
}

.OrderingStepPreviewImage5 {
  background: url('../../assets/images/food-trashes-game/ordering/magazin.png');
}

.OrderingStepPreviewImage6 {
  background: url('../../assets/images/food-trashes-game/ordering/otglejdane.png');
}

.OrderingGameStepsFill {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 10px 0 30px 0;
}

.OrderingStepFill {
  margin: 0 5px;
}

.OrderingStepFill input {
  background: url('../../assets/images/food-trashes-game/ordering/input.png');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  border: 0;
  width: 50px;
  height: 50px;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
}

.OrderingGameStepsCheckContainer {
  align-items: center;
  display: flex;
  justify-content: center;
}

@media (min-width: 576px) {

}

@media (min-width: 768px) {

}

@media (min-width: 992px) {
  .OrderingStepPreview {
    flex: 1 1;
  }
}
