.GameCanvasScreen {
  background-repeat: no-repeat !important;
  background-size: cover !important;
  padding: 20px;
  background-position: bottom center !important;
  min-height: 100%;
  width: 100%;
}

.GameCanvasScreen-l-1 {
  background: url('../assets/images/l-1-bg.png');
}

.GameCanvasScreen-l-2 {
  background: url('../assets/images/l-2-bg.png');
}

.GameCanvasScreen-l-3 {
  background: url('../assets/images/l-3-bg.png');
}

.GameCanvasScreen-l-4 {
  background: url('../assets/images/l-4-bg.png');
}

.GameCanvasScreen-l-5 {
  background: url('../assets/images/l-5-bg.png');
}

/* Screen header */
.GameCanvasScreenHeader {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  min-height: 90px;
  max-height: 90px;
}

.GameCanvasScreenHeaderRow {
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.GameCanvasScreenHeaderRow.center {
  justify-content: center;
}

.GoToHomeButton {
  background: url('../assets/images/home-btn.png');
  background-size: contain;
  background-repeat: no-repeat;
  height: 32px;
  width: 32px;
}

.GameCanvasScreenHeaderLeftButtons,
.GameCanvasScreenHeaderRightButtons {
  display: flex;
  flex-direction: row;
}

.InfoButton {
  background: url('../assets/images/info-icon.png');
  background-size: contain !important;
  background-repeat: no-repeat !important;
  cursor: pointer;
  height: 32px;
  width: 32px;
  margin-right: 10px;
}

.AudioButton {
  background-size: contain !important;
  background-repeat: no-repeat !important;
  cursor: pointer;
  height: 32px;
  width: 32px;
}

.AudioButton.playing {
  background: url('../assets/images/sound-btn.png');
}

.AudioButton.muted {
  background: url('../assets/images/mute-btn.png');
}

.GameCanvasScreenPointsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.GameCanvasScreenPoints {
  background-color: #68ac45;
  border: solid 2px #bed630;
  border-radius: 7.5px;
  color: white;
  font-family: 'Montserrat Alternates Bold';
  font-size: 22px;
  line-height: 1.2;
  max-width: 180px;
  padding: 0 20px;
}

.GameCanvasScreenExplain {
  background-color: #fff;
  border: solid 2px #bed630;
  border-radius: 7.5px;
  font-family: 'Montserrat Alternates Bold';
  margin-top: 10px;
  padding: 7px 18px;
  font-size: 10px;
}

/* Recycle bins */
.RecycleBinsContainerWrapper {
  bottom: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: absolute;
  width: calc(100% - 40px);
}

.RecycleBinsContainer {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  max-width: 900px;
}

.RecycleBin {
  background-repeat: no-repeat !important;
  background-size: contain !important;
  background-position: center !important;
  color: white;
  float: left;
  font-size: 1rem;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  min-height: 120px;
  min-width: 15%;
  padding: 1rem;
  position: relative;
  line-height: normal;
  text-align: center;
}

.RecycleBinnot_recyclable {
  background: url('../assets/images/silver-bin.png');
}

.RecycleBinplastic {
  background: url('../assets/images/yellow-bin.png');
}

.RecycleBinglass {
  background: url('../assets/images/green-bin.png');
}

.RecycleBinpaper {
  background: url('../assets/images/blue-bin.png');
}

.RecycleBincomposting {
  background: url('../assets/images/brown-bin.png');
}

.RecycleBinelectronics {
  background: url('../assets/images/red-bin.png');
}

.RecycleBincloset {
  background: url('../assets/images/food-trashes-game/dustbins/closet.png');
}

.RecycleBinfridge {
  background: url('../assets/images/food-trashes-game/dustbins/fridge.png');
}

.RecycleBinfreezer {
  background: url('../assets/images/food-trashes-game/dustbins/fridge.png');
}

.RecycleBinSuccess {
  background: url('../assets/images/check-ok.png');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  height: 80%;
  position: absolute;
  width: 80%;
  max-width: 50px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.RecycleBinError {
  background: url('../assets/images/cross-no-ok.png');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  height: 80%;
  position: absolute;
  width: 80%;
  max-width: 50px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* Trashes */
.TrashesContainer {
  bottom: 110px;
  clear: both;
  left: 0;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 90px;
}

.Trash {
  background-size: contain !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  cursor: move;
  float: left;
  max-height: 100px;
  max-width: 100px;
  position: absolute;
  transform: translate(0, 0);
}

@media (min-width: 576px) {
  .RecycleBin {
    min-width: 25%;
  }
}

@media (min-width: 768px) {
  .RecycleBin {
    min-width: 90px;
  }

  .GameCanvasScreenExplain {
    font-size: 12px;
  }
}

@media (min-width: 992px) {
  .GameCanvasScreen {
    height: 100%;
  }

  .GameCanvasScreenExplain {
    font-size: 14px;
  }
}

@media (max-height: 600px) {
  .RecycleBin {
    min-width: 60px;
    min-height: 80px;
  }

  .TrashesContainer {
    bottom: 100px;
  }
}
