/* Content screen */
.ContentScreen {
  background: url('../assets/images/main-bg.png');
  background-repeat: no-repeat !important;
  background-size: cover !important;
  font-size: 12px;
  background-position: bottom center !important;
  height: 100%;
  width: 100%;
}

.ContentScreen.recycling {
  background: url('../assets/images/main-bg.png');
}

.ContentScreen.trash-management {
  background: url('../assets/images/trash-management-game/background.png');
  background-position: top center !important;
}

.ContentScreen.food-trashes {
  background: url('../assets/images/food-trashes-game/background.png');
  background-position: center !important;
}

.ContentScreen .AppButtonDark,
.ContentScreen .AppButtonLight {
  padding: 5px 15px;
}

.ContentScreenHeader {
  align-items: stretch;
  display: flex;
  max-height: 140px;
  height: 90px;
  justify-content: space-between;
  flex-direction: column;
  padding-bottom: 10px;
}

.ContentScreenHeader .Row {
  width: 100%;
  max-height: 100px;
  display: flex;
  flex-direction: row;
}

.ContentScreenHeader .Row.Grow {
  flex-grow: 1;
}

.ContentScreenHeader .ChooseGameButton {
  padding: 7px 15px;
  border-radius: 7.5px;
  border: solid 2px #bed630;
  background-color: #ffd630;
  color: #000000;
  font-weight: bold;
  text-decoration: none;
}

.ContentScreenEuLogo {
  background: url('../assets/images/eu-logo.png');
  background-position: left;
}

.IntroThinkLogo {
  background: url('../assets/images/pomisli-logo.png');
  background-position: center;
}

.IntroProgramaLogo {
  background: url('../assets/images/programa-logo.png');
  background-position: right;
}

.ContentScreenContent {
  display: flex;
  flex-direction: row;
  max-height: 75%;
}

.ContentScreenContentLeft {
  background: white;
  border: 3px solid;
  border-color: #bed630;
  border-radius: 20px;
  padding: 2%;
  width: 100%;
  text-align: left;
}

.ContentScreenContentRight {
  background: url('../assets/images/katerica-skate.png');
  background-position: right;
  background-repeat: no-repeat;
  background-size: contain;
  display: none;
  margin-left: 2%;
  width: 32%;
}

.ContentScreenContentFull {
  background: white;
  border: 3px solid;
  border-color: #bed630;
  border-radius: 20px;
  padding: 2%;
  width: 100%;
  text-align: left;
}

.ContentScreenContentHeader {
  font-size: 16px;
  line-height: 1.2;
  color: #68ac45;
  text-align: center;
  margin-bottom: 5px;
}

.ContentScreenContentContent {
  display: flex;
  flex-direction: row;
  height: calc(100% - 140px);
}

.ContentScreenContentContentTitle {
  font-weight: bold;
}

.ContentScreenContentTrashCanContainer {
  width: 100%;
}

.ContentScreenContentTrashCanRow {
  display: flex;
  flex-direction: column;
}

.ContentScreenContentTrashCanWrap {
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  flex-basis: 0;
  margin-bottom: 20px;
}

.ContentScreenContentTrashCan {
  background-size: contain !important;
  background-repeat: no-repeat !important;
  max-width: 50px;
  min-height: 60px;
  width: 25%;
  flex-grow: 1;
  flex-shrink: 0;
}

.ContentScreenContentTrashCan.Gray {
  background: url('../assets/images/silver-bin.png');
}

.ContentScreenContentTrashCan.Yellow {
  background: url('../assets/images/yellow-bin.png');
}

.ContentScreenContentTrashCan.Green {
  background: url('../assets/images/green-bin.png');
}

.ContentScreenContentTrashCan.Blue {
  background: url('../assets/images/blue-bin.png');
}

.ContentScreenContentTrashCan.Brown {
  background: url('../assets/images/brown-bin.png');
}

.ContentScreenContentTrashCan.Red {
  background: url('../assets/images/red-bin.png');
}

.ContentScreenContentTrashCanDescription {
  padding-left: 10px;
}

.ContentScreenContentButtons {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}

.ContentScreenContentButtonsLeft {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.ContentScreenContentButtonsLeft .AppButtonDark,
.ContentScreenContentButtonsLeft .AppButtonLight {
  margin-right: 0;
  margin-bottom: 5px;
}

.ContentScreenContentButtonsRight {
  display: flex;
  flex-direction: column;
  flex-grow: 2;
  justify-content: flex-end;
}

.ContentScreenContentButtonsRight .AppButtonDark,
.ContentScreenContentButtonsRight .AppButtonLight {
  margin-left: 0;
  margin-bottom: 5px;
}

.ContentScreenFooter {
  align-items: center;
  display: flex;
  flex-direction: column;
  font-size: 10px;
  height: 8%;
  justify-content: center;
}

.ContentScreenFooter .Button {
  min-width: 240px;
  text-align: center;
}

.ContentScreenFooter p {
  margin-top: 0;
  margin-bottom: 5px;
}

.ContentScreenFooterContent {
  padding: 0 10px;
}

.ContentScreenFooterContent a {
  color: #000;
  font-weight: bold;
  text-decoration: none;
  text-transform: uppercase;
}

@media (min-width: 576px) {
  .ContentScreenHeader {
    height: 120px;
  }

  .ContentScreenContentContent {
    display: flex;
    flex-direction: row;
  }
}

@media (min-width: 768px) {
  .ContentScreenHeader {
    height: 120px;
  }
}

@media (min-width: 992px) {
  .ContentScreenHeader {
    height: 140px;
  }

  .ContentScreenContentHeader {
    font-size: 24px;
    text-align: left;
  }

  .ContentScreenFooter {
    flex-direction: row;
  }

  .ContentScreen {
    font-size: 14px;
  }

  .ContentScreenContentRight {
    display: block;
  }

  .ContentScreenContentLeft {
    width: 65%;
  }

  .ContentScreenContentContent {
    display: flex;
    flex-direction: column;
    height: calc(100% - 90px);
  }

  .ContentScreenContentButtons {
    flex-direction: row;
    margin-top: 20px;
  }

  .ContentScreenContentButtonsLeft {
    flex-direction: row;
  }

  .ContentScreenContentButtonsLeft .AppButtonDark,
  .ContentScreenContentButtonsLeft .AppButtonLight {
    margin-right: 10px;
    margin-bottom: 0;
  }

  .ContentScreenContentButtonsRight {
    flex-direction: row;
  }

  .ContentScreenContentButtonsRight .AppButtonDark,
  .ContentScreenContentButtonsRight .AppButtonLight {
    margin-left: 10px;
    margin-bottom: 0;
  }

  .ContentScreen .AppButtonDark,
  .ContentScreen .AppButtonLight {
    padding: 10px 15px;
  }

  .ContentScreenFooter {
    font-size: 12px;
  }
}

@media (min-width: 1200px) {
  .ContentScreenContentTrashCanRow {
    flex-direction: row;
  }
}

@media (max-height: 450px) {
  .ContentScreenHeader {
    height: 10%;
  }

  .ContentScreenFooter {
    height: 8%;
  }
}
